import React, { useContext } from 'react'
import cx from 'classnames'

import { ModalContext } from 'components/feedback'
import { Icon, IconColor, IconName, Text } from 'components/dataDisplay'


export type ModalHeadlineProps = {
  className?: string
  title: string
  text?: string
  iconName?: IconName
  iconColor?: IconColor
}

const ModalHeadline: React.FC<ModalHeadlineProps> = ({ className, title, text, iconName, iconColor }) => {
  const { titleId, textId } = useContext(ModalContext)

  return (
    <div className={cx(className, 'mb-32px')}>
      {
        Boolean(iconName) && (
          <Icon className="mb-32px" name={iconName} color={iconColor} />
        )
      }
      <Text
        id={titleId}
        message={title}
        size="h32"
        html
        dataTestId="title"
      />
      {
        Boolean(text) && (
          <Text
            className={cx('opacity-48', 'mt-12px')}
            id={textId}
            message={text}
            size="t16-20"
            color="titanic"
            html
            dataTestId="text"
          />
        )
      }
    </div>
  )
}


export default React.memo(ModalHeadline)
