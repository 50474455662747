import React from 'react'

import { Button } from 'components/inputs'
import type { ButtonProps } from 'components/inputs'


export type ModalButtonProps = Omit<ButtonProps, 'size' | 'style'>

export type ModalButtonsProps = {
  primary?: ModalButtonProps
  secondary?: ModalButtonProps
}

const ModalButtons: React.FC<ModalButtonsProps> = (props: any) => {
  const { primary, secondary } = props

  return (
    <div className="mt-8px flex">
      {
        secondary && (
          <Button
            {...secondary}
            style="secondary"
            size={40}
            fullWidth
            dataTestId="modalSecondaryButton"
          />
        )
      }
      {
        primary && (
          <Button
            className={secondary ? 'ml-32px' : null}
            {...primary}
            style="primary"
            size={40}
            fullWidth
            dataTestId="modalPrimaryButton"
          />
        )
      }
    </div>
  )
}


export default ModalButtons
