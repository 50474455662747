import cx from 'classnames'
import { useDevice } from 'device'
import TrapFocus from 'trap-focus'
import { createPortal } from 'react-dom'
import { useFreezeBodyScroll, useUniqueId } from 'hooks'
import React, { createContext, useCallback, useEffect } from 'react'

import { Overlay } from 'components/layout'
import { ButtonBase } from 'components/inputs'
import { Icon, IconColor } from 'components/dataDisplay'

import s from './PlainModal.scss'


export const ModalContext = createContext(null)

export type PlainModalProps = {
  className?: string
  overlayClosable?: boolean
  withCloseButton?: boolean
  closeModal: (withOnClose?: boolean) => void
  dataTestId?: string
  color?: 'rush' | 'white' | 'warrior'
  fullWidth?: boolean
  width?: 294 | 618
}

const PlainModal: React.FC<PlainModalProps> = (props) => {
  const {
    children, className, overlayClosable = true, withCloseButton = true,
    color = 'white', width, fullWidth, closeModal, dataTestId,
  } = props

  const { isTablet, isMobile } = useDevice()

  const uniqueId = useUniqueId()
  const titleId = `title_${uniqueId}`
  const textId = `text_${uniqueId}`
  const trapFocusId = `trapfocus_${uniqueId}`

  useFreezeBodyScroll()

  useEffect(() => {
    const trapFocusElement = document.getElementById(trapFocusId)

    // could be "null" if modal opened in iframe
    if (trapFocusElement) {
      const trapFocus = new TrapFocus(trapFocusElement, { withInitialFocus: false })

      trapFocusElement.focus()
      trapFocus.mount()

      return () => {
        trapFocus.unmount()
      }
    }
  }, [ trapFocusId ])

  const handleOverlayClick = useCallback(() => {
    if (overlayClosable) {
      closeModal(true)
    }
  }, [ overlayClosable, closeModal ])

  const handleCloseButtonClick = useCallback(() => {
    closeModal(true)
  }, [ closeModal ])

  const handleModalClick = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const closeButtonColor = {
    white: 'rocky', // 'pianist',
    rush: 'rocky',
    warrior: 'platoon',
  }[color]

  return createPortal(
    <ModalContext.Provider value={{ titleId, textId }}>
      <Overlay className={s.overlay} onClick={handleOverlayClick}>
        <div
          className={cx(s.container, 'flex items-stretch', {
            [`bg-${color}`]: color,
          })}
        >
          <div
            id={trapFocusId}
            className={cx(s.plainModal, className, 'relative', {
              ['flex flex-col items-center justify-center px-40px pt-48px pb-40px']: color === 'white',
              ['px-80px py-56px']: color === 'rush',
            })}
            tabIndex={0}
            role="dialog"
            aria-modal="true"
            aria-labelledby={titleId}
            aria-describedby={textId}
            data-testid={dataTestId}
            onClick={handleModalClick}
          >
            {
              withCloseButton && (
                <ButtonBase
                  className="absolute right-0 top-0"
                  onClick={handleCloseButtonClick}
                >
                  <Icon
                    className={s.closeButton}
                    name={isMobile ? 'action/close_16' : isTablet ? 'action/close_20' : 'action/close_32'}
                    color={closeButtonColor as IconColor}
                  />
                </ButtonBase>
              )
            }
            <div
              className={cx('modal', {
                'w-full': fullWidth,
                'mw-584': color === 'white' && !fullWidth && !width,
                'mw-294': width === 294,
                'mw-618': width === 618,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </Overlay>
    </ModalContext.Provider>,
    document.getElementById('modals')
  )
}


export default PlainModal
