import React from 'react'
import { useDevice } from 'device'

import { Text } from 'components/dataDisplay'


type ModalBasementNoteProps = {
  message: string
}

const ModalBasementNote: React.FC<ModalBasementNoteProps> = ({ message }) => {
  const { isMobile } = useDevice()

  return (
    <div className={`mt-36px ${isMobile ? 'text-left' : 'text-center'}`}>
      <Text tag="span" size="t16-20" message={message} html />
    </div>
  )
}


export default React.memo(ModalBasementNote)
