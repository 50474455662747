import React, { ReactNode } from 'react'

import { PlainModal, ModalHeadline, ModalButtons, ModalBasementNote } from 'components/feedback'
import type { PlainModalProps, ModalHeadlineProps, ModalButtonProps } from 'components/feedback'


export type ModalProps =
  Omit<PlainModalProps, 'ariaLabelledBy' | 'ariaDescribedby'>
  & Omit<ModalHeadlineProps, 'titleId' | 'textId'>
  & {
    headlineClassName?: string
    basementNote?: string
    primaryButton?: ModalButtonProps
    secondaryButton?: ModalButtonProps
    topNode?: ReactNode
    withTitle?: boolean
  }

const Modal: React.FC<ModalProps> = (props) => {
  const {
    children, className, headlineClassName,
    title, text, iconName, iconColor, basementNote,
    primaryButton, secondaryButton, color, fullWidth, width,
    withCloseButton, topNode, withTitle = true,
    overlayClosable,
    closeModal,
    dataTestId,
  } = props

  const areButtonsVisible = Boolean(primaryButton || secondaryButton)

  return (
    <PlainModal
      className={className}
      color={color}
      width={width}
      fullWidth={fullWidth}
      overlayClosable={overlayClosable}
      withCloseButton={withCloseButton}
      closeModal={closeModal}
      dataTestId={dataTestId}
    >
      {topNode}
      {
        Boolean(withTitle && (title || text)) && (
          <ModalHeadline
            className={headlineClassName}
            title={title}
            text={text}
            iconName={iconName}
            iconColor={iconColor}
          />
        )
      }
      <div className="flex flex-1 flex-col items-stretch justify-start">{children}</div>
      {
        areButtonsVisible && (
          <ModalButtons
            primary={primaryButton}
            secondary={secondaryButton}
          />
        )
      }
      {
        Boolean(basementNote) && (
          <ModalBasementNote message={basementNote} />
        )
      }
    </PlainModal>
  )
}


export default Modal
