import { getElementPosition } from 'helpers/getters'
import { devicePx } from 'helpers'


const setMobilePosition = ({ trigger, tooltip }) => {
  const horizontalMobilePadding = devicePx(40)
  const verticalMobilePadding = devicePx(16)
  const viewportWidth = document.documentElement.clientWidth
  const triggerPosition = getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  let tooltipBounds = tooltip.getBoundingClientRect()

  let top
  let left
  let right

  // if distance between viewport's right side and trigger is less than tooltip width
  // then the tooltip should be placed to the left of the trigger
  if (triggerBounds.right + tooltipBounds.width > viewportWidth) {
    left = triggerPosition.left + triggerBounds.width - tooltipBounds.width

    // if tooltip is off-screened
    if (left < 0) {
      if (triggerPosition.left < horizontalMobilePadding) {
        left = triggerPosition.left
      }
      else {
        left = horizontalMobilePadding
      }
    }

    // if tooltip doesn't fit the space between left and right sides of the viewport
    // by design the left and right padding size for full width tooltips is horizontalMobilePaddingpx
    if (tooltipBounds.width + left > viewportWidth - horizontalMobilePadding) {
      if (viewportWidth - triggerPosition.left - triggerBounds.width < horizontalMobilePadding) {
        right = viewportWidth - triggerPosition.left - triggerBounds.width
      }
      else {
        right = horizontalMobilePadding
      }
    }
  }
  else {
    left = triggerPosition.left
  }

  tooltip.style.left = `${left}px`
  tooltip.style.right = right ? `${right}px` : 'auto'

  tooltipBounds = tooltip.getBoundingClientRect()

  // ATTN top should be calculated after "left" and "right" because height of the tooltip can change (text wrapping)
  // if distance between viewport's top side and trigger is less than tooltip height
  // then the tooltip should be placed to the bottom of the trigger
  if (triggerBounds.top - tooltipBounds.height < verticalMobilePadding) {
    top = triggerPosition.top + triggerBounds.height
  }
  else {
    top = triggerPosition.top - tooltipBounds.height
  }

  tooltip.style.top = `${top}px`
}

// TODO rework this
const setDesktopPosition = ({ trigger, tooltip, position }) => {
  const verticalPadding = 16
  const viewportWidth = document.documentElement.clientWidth
  const viewportHeight = document.documentElement.clientHeight
  const triggerPosition = getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  const tooltipBounds = tooltip.getBoundingClientRect()
  const triggerHorizontal = triggerBounds.width / 2
  const triggerVertical = 14

  let top
  let left

  // if distance between viewport's bottom side and trigger is less than tooltip height
  // then the tooltip should be placed to the top of the trigger
  if (!position?.includes('bottom') && viewportHeight - triggerBounds.bottom - tooltipBounds.height - verticalPadding) {
    top = triggerPosition.top - tooltipBounds.height - triggerVertical
  }
  else {
    top = triggerPosition.top + triggerBounds.height
  }

  // if distance between viewport's right side and trigger is less than tooltip width
  // then the tooltip should be placed to the left of the trigger
  if (triggerBounds.right + tooltipBounds.width > viewportWidth) {
    left = triggerPosition.left + triggerBounds.width - tooltipBounds.width + triggerHorizontal

    // if tooltip is off-screened
    if (left < 0) {
      left = verticalPadding
    }
  }
  else {
    left = triggerPosition.left
  }

  tooltip.style.top = `${top}px`
  tooltip.style.left = `${left}px`
}

const setTooltipPosition = ({ trigger, tooltip, isMobile, position }) => {
  if (isMobile) {
    setMobilePosition({ trigger, tooltip, position })
  }
  else {
    setDesktopPosition({ trigger, tooltip, position })
  }
}

const createTooltip = ({ trigger, tooltip, styles, isMobile, position }) => {
  const show = () => {
    // remove style attribute from previous execution
    tooltip.removeAttribute('style')

    // show tooltip to allow the script to calculate tooltip width / height
    tooltip.classList.add(styles.visible)

    setTooltipPosition({ tooltip, trigger, isMobile, position })

    document.addEventListener('touchstart', hide, { passive: true, capture: true })
    document.addEventListener('keydown', hideOnEscapePress)
    window.addEventListener('blur', hide)
    window.addEventListener('scroll', hide)
  }

  const hide = () => {
    tooltip.classList.remove(styles.visible)

    document.removeEventListener('touchstart', hide)
    document.removeEventListener('keydown', hideOnEscapePress)
    window.removeEventListener('blur', hide)
    window.removeEventListener('scroll', hide)
  }

  const hideOnEscapePress = (event) => {
    if (event.key === 'Escape') {
      hide()
    }
  }

  trigger.addEventListener('focus', show)
  trigger.addEventListener('touchstart', show)
  trigger.addEventListener('mouseenter', show)
  trigger.addEventListener('mouseleave', hide)
  trigger.addEventListener('blur', hide)

  // unmount callback for useEffect
  return () => {
    trigger.removeEventListener('focus', show)
    trigger.removeEventListener('touchstart', show)
    trigger.removeEventListener('mouseenter', show)
    trigger.removeEventListener('mouseleave', hide)
    trigger.removeEventListener('blur', hide)

    document.removeEventListener('touchstart', hide)
    document.removeEventListener('keydown', hideOnEscapePress)
    window.removeEventListener('blur', hide)
    window.removeEventListener('scroll', hide)
  }
}


export default createTooltip
